import { Button, Flex, Layout } from "antd";

const { Header: LayoutHeader } = Layout;

type Props = {
  logoUrl: string;
};

export const Header: React.FC<Props> = ({ logoUrl }) => {
  return (
    <LayoutHeader
      style={{
        /* backgroundColor: "#00aeef", */ backgroundColor: "white",
        borderBottom: "1px solid #01adef66",
        padding: 10,
        paddingRight: 20,
        height: 65,
        boxShadow: "0 1px 4px rgba(0,21,41,.98)",
      }}
    >
      <Flex justify="space-between" align="center">
        <img src={logoUrl} alt="logo" style={{ height: 40, padding: "8px 0 0px 8px" }} />
        <Button size="small" type="text" style={{ fontWeight: 600 }}>
          Login
        </Button>
      </Flex>
    </LayoutHeader>
  );
};
