import React, { useState } from "react";
import BingMaps from "ol/source/BingMaps";
import TileLayer from "ol/layer/Tile";
import { useMap } from "../app/MapContext";
import { Select } from "antd";
import { OSM } from "ol/source";
import { AppstoreOutlined } from "@ant-design/icons";
import { CustomControl } from "./base/CustomControl";

const styles = ["OSM", "RoadOnDemand", /*  "Aerial", */ "AerialWithLabelsOnDemand", "CanvasDark" /* "OrdnanceSurvey" */];

export const BaseLayerControl: React.FC = () => {
  const [baseLayer, setBaseLayer] = useState(styles[0]); // styles[0] is OSM

  const { map } = useMap();

  const onChangeStyle = (value: string) => {
    if (map) {
      map.getLayers().forEach((layer) => {
        if (layer instanceof TileLayer) {
          if (layer.getSource() instanceof OSM) {
            layer.setVisible(false);
          } else if (layer.getSource() instanceof BingMaps) {
            layer.setVisible(false);
          }
          // layer.setVisible(false);
        }
      });

      setBaseLayer(value);

      // OSM
      if (value === "OSM") {
        const baseLayer = map
          .getLayers()
          .getArray()
          .find((layer) => {
            if (layer instanceof TileLayer) {
              return layer.getSource() instanceof OSM;
            }
            return false;
          });

        if (baseLayer) {
          baseLayer.setVisible(true);
        }
        return;
      }

      // BING
      else {
        const baseLayer = map
          .getLayers()
          .getArray()
          .find((layer) => {
            if (layer instanceof TileLayer) {
              return layer.getSource() instanceof BingMaps && layer.getSource().getImagerySet() === value;
            }
            return false;
          });

        if (baseLayer) {
          baseLayer.setVisible(true);
        }
      }
    }
  };

  return (
    <CustomControl title="Base Layers" Icon={AppstoreOutlined} classname="ucontrol-baselayer" key="baselayer">
      <Select value={baseLayer} style={{ width: 220 }} onChange={onChangeStyle}>
        {styles.map((style) => (
          <Select.Option key={style} value={style}>
            {style}
          </Select.Option>
        ))}
      </Select>
    </CustomControl>
  );
};
