import React, { FC, useCallback, useEffect, useState } from "react";
import { useMap } from "../app/MapContext";
import TileLayer from "ol/layer/Tile";
import { TileWMS } from "ol/source";
import { ColumnsType } from "antd/es/table";
import { Table, Collapse, Spin, Flex } from "antd";
const { Panel } = Collapse;

type DataType = {
  key: string;
  value: string;
};

type TableData = {
  title: string;
  rows: DataType[];
};

type Props = {};

export const GetInfo: React.FC<Props> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tables, setTables] = useState<TableData[]>();

  const { map } = useMap();

  const getLayerInfo = useCallback(
    async (evt: any) => {
      if (!map) return;

      setLoading(true);
      setTables([]);
      map.getTargetElement().style.cursor = "wait";

      const layers = map.getLayers().getArray();
      const view = map.getView();
      const viewResolution = view.getResolution();
      if (!viewResolution) return;

      const projection = view.getProjection();
      const urls: { url: string; layerTitle: string }[] = [];

      // Tüm katmanlar için GetFeatureInfo URL'lerini birleştir
      layers.forEach((layer, i) => {
        if (layer instanceof TileLayer && layer.getVisible()) {
          const source = layer.getSource();
          if (source instanceof TileWMS) {
            const url = source.getFeatureInfoUrl(evt.coordinate, viewResolution, projection, { INFO_FORMAT: "application/json", FEATURE_COUNT: 1 });
            if (url) {
              if (urls.some((u) => u.layerTitle === (layer as any).title)) return;
              urls.push({ url: url, layerTitle: (layer as any).title ?? "Layer " + i });
            }
          }
        }
      });

      // URL'ler üzerinden bilgi al
      for (let i = 0; i < urls.length; i++) {
        const url = urls[i];
        try {
          const response = await fetch(url.url);
          const json = await response.json();
          const features = json.features;
          if (features.length > 0) {
            const feature = features[0];
            const properties = feature.properties;

            let rows = Object.keys(properties).map((key) => ({ key: key, value: properties[key] }));
            rows = rows.filter((row) => row.value !== null && row.value !== undefined && row.value !== "");
            setTables((prev) => [...(prev ?? []), { title: url.layerTitle, rows: rows }]);
          }
        } catch (error) {
          console.error("Error fetching layer info:", error);
        } finally {
        }
      }

      setLoading(false);
      map.getTargetElement().style.cursor = "crosshair";
    },
    [map]
  );

  useEffect(() => {
    if (!map) return;

    map.on("singleclick", getLayerInfo);
    map.getTargetElement().style.cursor = "crosshair";

    return () => {
      if (map) {
        map.un("singleclick", getLayerInfo);
        map.getTargetElement().style.cursor = "";
      }
    };
  }, [getLayerInfo, map]);

  return (
    <>
      <div className="help-pre">Click on map to get information</div>
      <div className="help">
        <div className="help-title">Help</div>
        <div className="help-body">Click on the map to get information about the features under the cursor.</div>
      </div>
      {loading && (
        <Flex justify="center" style={{ padding: 8 }}>
          <Spin />
        </Flex>
      )}
      {loading === false && tables && tables.length === 0 && <div style={{ textAlign: "center", fontWeight: 500, fontSize: 14, paddingBottom: 14 }}>No information found</div>}
      {loading === false && tables && (
        <Collapse accordion defaultActiveKey={0} className="custom-collapse">
          {tables.map((table, i) => (
            <Panel header={table.title} key={i}>
              <InfoTable tableData={table} />
            </Panel>
          ))}
        </Collapse>
      )}
    </>
  );
};

const InfoTable: FC<{ tableData: TableData; title?: boolean }> = ({ tableData, title = false }) => {
  const columns: ColumnsType<DataType> = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const data: DataType[] = tableData.rows;

  return (
    <div>
      {title && <h2>{tableData.title}</h2>}
      <Table columns={columns} dataSource={data} pagination={false} showHeader={false} className="custom-table" />
    </div>
  );
};
