import React, { useEffect } from "react";
import { useMap } from "../app/MapContext";
import { toLonLat } from "ol/proj";
import { Button, Flex, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";

type Props = {};

export const ShowCoordinate: React.FC<Props> = () => {
  const [webMercator, setWebMercator] = React.useState<string[]>(["", ""]);
  const [wgs84, setWgs84] = React.useState<string[]>(["", ""]);

  const { map } = useMap();

  useEffect(() => {
    if (!map) return;

    const onMapClick = (e: any) => {
      setWebMercator([e.coordinate[0].toString(), e.coordinate[1].toString()]);
      setWgs84([toLonLat(e.coordinate)[0].toString(), toLonLat(e.coordinate)[1].toString()]);
      // console.log(e.coordinate, toLonLat(e.coordinate));
    };

    map.on("click", onMapClick);

    return () => {
      if (map) {
        map.un("click", onMapClick);
      }
    };
  }, [map]);

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Coordinate copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy text to clipboard.");
      });
  };

  return (
    <>
      <div className="help-pre">Click on map to get coordinate</div>
      {webMercator[0] === "" && (
        <div className="help">
          <div className="help-title">Help</div>
          <div className="help-body">Display the coordinates of the location left-clicked on the map in multiple coordinate reference system.</div>
        </div>
      )}
      {webMercator[0] && (
        <>
          <Flex justify="center" align="center" vertical style={{ borderBottom: "1px solid gray", paddingBottom: 8 }}>
            <h3>WGS 84</h3>
            <p>{wgs84[1]}</p>
            <p>{wgs84[0]}</p>
            <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(`${wgs84[1]},${wgs84[0]}`)}>
              Copy
            </Button>
          </Flex>

          <Flex justify="center" align="center" vertical style={{ borderBottom: "1px solid gray", paddingBottom: 8 }}>
            <h3>Web Mercator</h3>
            <p>{webMercator[1]}</p>
            <p>{webMercator[0]}</p>
            <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(`${webMercator[1]},${webMercator[0]}`)}>
              Copy
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};
