import React, { FC, useState } from "react";
import { useMap } from "../../app/MapContext";
import { Button, Flex } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { CustomControlButton } from "./CustomControlButton";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";

type Props = {
  title: string;
  Icon: FC<AntdIconProps>;
  classname: string;
  children: React.ReactNode;
};

export const CustomControl: FC<Props> = ({ title, Icon, classname, children }) => {
  const [active, setActive] = useState(false);

  const { map } = useMap();

  if (!map) return null;

  if (!active)
    return (
      <CustomControlButton classname={classname} key="baselayer">
        <Button icon={<Icon style={{ height: 32, verticalAlign: "middle" }} />} onClick={() => setActive(true)} className="no-border-button" />
      </CustomControlButton>
    );

  return (
    <CustomControlButton classname={classname} key="baselayer">
      <Flex justify="space-between">
        <h4 style={{ margin: "8px 0 0 8px" }}>{title}</h4>
        <Button icon={<CloseOutlined style={{ height: 32, verticalAlign: "middle" }} />} onClick={() => setActive(false)} className="no-border-button" />
      </Flex>
      <>{children}</>
    </CustomControlButton>
  );
};
