import React, { useEffect, useState } from "react";
import { useMap } from "../app/MapContext";
import { Button, Layout, Tree } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { GEOSERVER_WMS_LAYERS_GROUPS } from "../config/GEOSERVER_WMS_LAYERS_GROUPS";
import { WmsLayerInfo } from "../config/type";
import ExtLayersControl from "./ExtLayersControl";

const { Sider } = Layout;

const { TreeNode } = Tree;

const SiderLayersControl: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  const { fetchAndAddLayerGroup, setLayerVisibility, getLayerVisibility } = useMap();
  const [layers, setLayers] = useState<WmsLayerInfo[]>([]);

  useEffect(() => {
    if (fetchAndAddLayerGroup === undefined) return;

    const fetchLayers = async () => {
      const allLayers = [];
      for (const group of GEOSERVER_WMS_LAYERS_GROUPS) {
        const fetchedLayers = await fetchAndAddLayerGroup(group.url, group.groupName, group.visibleLayerNames ?? []);
        allLayers.push(...fetchedLayers);
      }
      setLayers(allLayers);
    };

    fetchLayers();
  }, [fetchAndAddLayerGroup]);

  const onCheck = (checkedKeys: any, nodeKey: string, checked: boolean) => {
    const [groupTitle, layerName] = nodeKey.split("#");

    if (nodeKey === groupTitle) {
      layers
        .filter((x) => x.groupTitle === groupTitle)
        .forEach((layer) => {
          setLayerVisibility(layer.layerName, checked);
        });
    } else {
      setLayerVisibility(layerName, checked);
    }
  };

  const renderTreeNodes = (layers: any[]) => {
    const groupedLayers = layers.reduce((acc, layer) => {
      if (!acc[layer.groupTitle]) {
        acc[layer.groupTitle] = [];
      }
      acc[layer.groupTitle].push(layer);
      return acc;
    }, {} as any);

    return Object.keys(groupedLayers).map((groupTitle) => (
      <TreeNode title={groupTitle} key={groupTitle} selectable={false}>
        {groupedLayers[groupTitle].map((layer: any) => (
          <TreeNode title={layer.layerTitle} key={`${layer.groupTitle}#${layer.layerName}`} />
        ))}
      </TreeNode>
    ));
  };

  const checkedKeys = layers.filter((layer) => getLayerVisibility(layer.layerName)).map((layer) => layer.groupTitle + "#" + layer.layerName);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={350}
      collapsedWidth={0}
      style={{ height: "calc(100vh - 70px)", backgroundColor: "white", borderRight: "1px solid #01adef66" }}
      className={collapsed ? "usider-layers-closed" : "usider-layers-opened"}
    >
      <Button
        size="small"
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          position: "absolute",
          top: 4,
          left: collapsed ? 4 : 304,
          fontSize: 16,
          width: 32,
          height: 32,
          zIndex: 200,
          backgroundColor: "white",
        }}
      />
      <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
        <h4 style={{ margin: "12px 24px" }}>Layers</h4>
        <hr />
        <Tree
          checkable
          selectable={false}
          defaultExpandAll
          defaultCheckedKeys={checkedKeys}
          onCheck={(checkedKeys, info) => {
            onCheck(checkedKeys, info.node.key as string, info.checked);
          }}
        >
          {renderTreeNodes(layers)}
        </Tree>
        <ExtLayersControl />
      </div>
    </Sider>
  );
};

export default React.memo(SiderLayersControl);
