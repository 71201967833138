import React, { createContext, useContext, useRef, useEffect, useState, ReactNode } from "react";
import "ol/ol.css";
import { Map } from "ol";
import TileLayer from "ol/layer/Tile";
import { FullScreen, MousePosition, defaults, ScaleLine } from "ol/control";
import BASE_LAYERS from "../const/BASE_LAYERS";
import view from "../const/VIEW";
import { createStringXY } from "ol/coordinate";
import { TileWMS } from "ol/source";
import { getWmsLayerInfos } from "../utils/getWmsLayerInfos";
import { WmsLayerInfo } from "../config/type";
import { MapPageLayout } from "../layout";

interface MapContextProps {
  mapRef: React.RefObject<HTMLDivElement>;
  map: Map | null;
  // addWmsLayer: (id: string, url: string, params: any, visible: boolean) => void;
  setLayerVisibility: (id: string, visible: boolean) => void;
  getLayerVisibility: (id: string) => boolean;
  fetchAndAddLayerGroup: (url: string, groupName: string, visibleLayerNames: string[], filterLayers?: string[]) => Promise<WmsLayerInfo[]>;
}

const MapContext = createContext<MapContextProps | undefined>(undefined);

interface MapProviderProps {
  children: ReactNode;
}

export const MapProvider: React.FC<MapProviderProps> = ({ children }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);

  useEffect(() => {
    if (mapRef.current) {
      const mapObject = new Map({
        target: mapRef.current,
        layers: BASE_LAYERS,
        view,
        controls: defaults({
          zoomOptions: { className: "ucontrol-zoom" },
        }).extend([
          new FullScreen({ className: "ucontrol-fullscreen" }),
          new MousePosition({ coordinateFormat: createStringXY(4), projection: "EPSG:4326", className: "ucontrol-mouseposition" }),
          new ScaleLine({ bar: true, text: true, minWidth: 125, dpi: 96 }),
        ]),
      });

      setMap(mapObject);
      (window as any).map = mapObject;

      return () => {
        mapObject.setTarget(undefined);
      };
    }
  }, []);

  const addWmsLayer = (layerName: string, url: string, visible: boolean, layerTitle: string) => {
    if (!map) return;

    const wmsLayer = new TileLayer({
      source: new TileWMS({
        url: url,
        params: { TILED: true, LAYERS: layerName },
        crossOrigin: "Anonymous",
        serverType: "geoserver",
      }),
      visible: visible,
    });

    (wmsLayer as any).id = layerName;
    (wmsLayer as any).title = layerTitle;
    map.addLayer(wmsLayer);
  };

  const setLayerVisibility = (layerId: string, visible: boolean) => {
    if (!map) return;

    map
      .getLayers()
      .getArray()
      .forEach((layer) => {
        if ((layer as any).id === layerId) {
          layer.setVisible(visible);
        }
      });
  };

  const getLayerVisibility = (layerId: string) => {
    if (!map) return false;

    let visible = false;
    map
      .getLayers()
      .getArray()
      .forEach((layer) => {
        if ((layer as any).id === layerId) {
          visible = layer.getVisible();
        } else {
          visible = false;
        }
      });

    return visible;
  };

  const fetchAndAddLayerGroup = async (url: string, groupName: string, visibleLayerNames: string[], filterLayers: string[] = []) => {
    const layers = await getWmsLayerInfos(url, groupName, visibleLayerNames);
    const filteredLayers = filterLayers.length > 0 ? layers.filter((layer) => filterLayers.includes(layer.layerName.split(":")[1])) : layers;
    filteredLayers.forEach((layer) => {
      addWmsLayer(layer.layerName, url, layer.layerVisible, layer.layerTitle);
    });
    return filteredLayers;
  };

  return (
    <MapContext.Provider value={{ mapRef, map, /* addWmsLayer, */ setLayerVisibility, getLayerVisibility, fetchAndAddLayerGroup }}>
      <MapPageLayout>
        <div ref={mapRef} style={{ position: "relative", width: "100%", height: "100%" }}>
          {children}
        </div>
      </MapPageLayout>
    </MapContext.Provider>
  );
};

export const useMap = (): MapContextProps => {
  const context = useContext(MapContext);
  if (!context) {
    throw new Error("useMap must be used within a MapProvider");
  }
  return context;
};
