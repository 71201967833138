import React from "react";
import { MapProvider } from "./MapContext";
import { BaseLayerControl } from "../controls/BaseLayerControl";
import { PrintControl } from "../controls/PrintControl";

const App: React.FC = () => {
  return (
    <MapProvider>
      <BaseLayerControl />
      <PrintControl />
    </MapProvider>
  );
};

export default App;
