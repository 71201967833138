import React from "react";
import { useMap } from "../app/MapContext";
import { Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { CustomControlButton } from "./base/CustomControlButton";
// import { getPointResolution } from "ol/proj";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ScaleLine /* defaults as defaultControls */ } from "ol/control.js";

export const PrintControl: React.FC = () => {
  const { map } = useMap();

  const onPrint = () => {
    const mapCenter = map?.getView().getCenter();
    const scaleLine = map
      ?.getControls()
      .getArray()
      .find((control) => control instanceof ScaleLine) as ScaleLine;

    if (map && mapCenter) {
      // console.log("Print", mapCenter);

      const exportOptions: any = {
        useCORS: true,
        /* ignoreElements: function (element: any) {
          const className = element.className || "";
          return className.includes("ol-control") && !className.includes("ol-scale") && (!className.includes("ol-attribution") || !className.includes("ol-uncollapsible"));
        }, */
      };

      document.body.style.cursor = "progress";

      const format = "a4";
      const resolution = 96;
      // const scale = 100;
      const dim = [297, 210];
      const width = Math.round((dim[0] * resolution) / 25.4);
      const height = Math.round((dim[1] * resolution) / 25.4);
      const viewResolution = map.getView().getResolution();
      // const scaleResolution = scale / getPointResolution(map.getView().getProjection(), resolution / 25.4, mapCenter);

      map.once("rendercomplete", function () {
        exportOptions.width = width;
        exportOptions.height = height;
        html2canvas(map.getViewport(), exportOptions).then(function (canvas) {
          const pdf = new jsPDF("landscape", undefined, format);
          pdf.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 0, dim[0], dim[1]);
          pdf.save("map.pdf");
          // Reset original map size
          scaleLine.setDpi(96);
          map.getTargetElement().style.width = "100%";
          map.getTargetElement().style.height = "100%";
          map.updateSize();
          map.getView().setResolution(viewResolution);
          // exportButton.disabled = false;
          document.body.style.cursor = "auto";
        });
      });

      // Set print size
      scaleLine.setDpi(resolution);
      map.getTargetElement().style.width = width + "px";
      map.getTargetElement().style.height = height + "px";
      map.updateSize();
      // map.getView().setResolution(scaleResolution);

      return;
    }
  };

  /* const exportMapToPDF = () => {
    if (!mapRef.current) return;

    const mapElement = mapRef.current;
    html2canvas(mapElement, {
      useCORS: true,
      logging: true,
      allowTaint: true,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("landscape", "pt", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("map.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }; */

  return (
    <CustomControlButton classname="ucontrol-print" key="print">
      <Button icon={<PrinterOutlined style={{ height: 32, verticalAlign: "middle" }} />} onClick={() => onPrint()} />
    </CustomControlButton>
  );
};
