import { Layout } from "antd";
import { useState } from "react";
import { Toolbox } from "../toolbox";
import { AimOutlined, EditOutlined, FileSearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ZoomToCoordinate } from "../toolbox/ZoomToCoordinate";
import { ZoomToRectangle } from "../toolbox/ZoomToRectangle";
import { ShowCoordinate } from "../toolbox/ShowCoordinate";
import { MeasureControl } from "../toolbox/MeasureControl";
import { GetInfo } from "../toolbox/GetInfo";
import { DragDropFile } from "../toolbox/DragDropFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DrawControl } from "../toolbox/DrawControl";

const { Sider } = Layout;

type Props = {};

export const SiderToolbox: React.FC<Props> = () => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      reverseArrow
      width="350"
      collapsedWidth="40"
      className="sider-toolbox"
      style={{
        /* backgroundColor: "#f2f2f2", */
        backgroundColor: "#fff",
        height: "calc(100vh - 115px)",
        overflow: "auto",
        padding: "1px 0 6px ",
        borderLeft: "1px solid #01adef66",
      }}
    >
      <Toolbox collapsed={collapsed} setCollapsed={setCollapsed} icon={<InfoCircleOutlined style={{ fontSize: 24 }} />} title="Identify" contenRerender>
        <GetInfo />
      </Toolbox>
      <Toolbox collapsed={collapsed} setCollapsed={setCollapsed} icon={<AimOutlined style={{ fontSize: 24 }} />} title="Zoom to Coordinate">
        <ZoomToCoordinate />
      </Toolbox>
      <Toolbox collapsed={collapsed} setCollapsed={setCollapsed} icon={<FileSearchOutlined style={{ fontSize: 24 }} />} title="Zoom to Rectangle" contenRerender>
        <ZoomToRectangle />
      </Toolbox>
      <Toolbox
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        icon={<FontAwesomeIcon icon="map-marker-alt" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />}
        title="Show Coordinate"
        contenRerender
      >
        <ShowCoordinate />
      </Toolbox>
      <Toolbox
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        icon={<FontAwesomeIcon icon="ruler-combined" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />}
        title="Measure"
      >
        <MeasureControl />
      </Toolbox>

      <Toolbox collapsed={collapsed} setCollapsed={setCollapsed} icon={<EditOutlined />} title="Draw">
        <DrawControl />
      </Toolbox>

      <DragDropFile />
    </Sider>
  );
};
