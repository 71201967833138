import { useEffect } from "react";
import { useMap } from "../app/MapContext";
import { GeoJSON, KML } from "ol/format";
import DragAndDrop from "ol/interaction/DragAndDrop";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";

export const DragDropFile = () => {
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;

    const dragAndDropInteraction = new DragAndDrop({
      formatConstructors: [GeoJSON, new KML({ extractStyles: true })],
    });
    dragAndDropInteraction.on("addfeatures", function (event) {
      const vectorSource = new VectorSource({ features: event.features });
      map.addLayer(
        new VectorLayer({
          source: vectorSource,
        })
      );
      map.getView().fit(vectorSource.getExtent());
    });
    map.addInteraction(dragAndDropInteraction);
  }, [map]);

  return null;
};
