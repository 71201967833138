import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMap } from "../app/MapContext";
import VectorSource from "ol/source/Vector";
import Draw, { DrawEvent } from "ol/interaction/Draw.js";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import { Geometry, LineString, Polygon } from "ol/geom.js";
import { getArea, getLength } from "ol/sphere.js";
import { unByKey } from "ol/Observable.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { Feature, /* MapBrowserEvent, */ Overlay } from "ol";
import { EventsKey } from "ol/events";
import { Coordinate } from "ol/coordinate";
import { Radio, RadioChangeEvent } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MeasureControl: React.FC = () => {
  const { map } = useMap();

  const source = useMemo(() => new VectorSource(), []);

  const vector = useMemo(() => {
    const instance = new VectorLayer({
      source,
      style: {
        "fill-color": "rgba(255, 255, 255, 0.2)",
        "stroke-color": "#ffcc33",
        "stroke-width": 2,
        "circle-radius": 7,
        "circle-fill-color": "#ffcc33",
      },
    });

    (instance as any).id = "measure-layer";
    return instance;
  }, [source]);

  const [draw, setDraw] = useState<Draw>();
  const [drawType, setDrawType] = useState<"length" | "area">();

  const sketch = useRef<Feature<Geometry>>();
  const listener = useRef<EventsKey>();

  const measureTooltipElement = useRef<HTMLDivElement>();
  const measureTooltip = useRef<Overlay>();

  const addInteraction = useCallback(
    (drawShape: "length" | "area") => {
      if (!map) return;

      const type = drawShape === "area" ? "Polygon" : "LineString";
      const drawInstaince = new Draw({
        source,
        type: type,
        style: (feature) => {
          const geometryType = feature.getGeometry()?.getType();
          if (geometryType === type || geometryType === "Point") {
            return style;
          }
        },
      });
      setDraw(drawInstaince);
      map.addInteraction(drawInstaince);
      map.getTargetElement().style.cursor = "crosshair";

      const createMeasureTooltip = () => {
        if (!map) return;

        if (measureTooltipElement.current) {
          measureTooltipElement.current.parentNode?.removeChild(measureTooltipElement.current);
        }
        measureTooltipElement.current = document.createElement("div");
        measureTooltipElement.current.className = "ol-tooltip ol-tooltip-measure";
        measureTooltip.current = new Overlay({
          element: measureTooltipElement.current,
          offset: [0, -15],
          positioning: "bottom-center",
          stopEvent: false,
          insertFirst: false,
        });
        map.addOverlay(measureTooltip.current);
      };

      createMeasureTooltip();
      // createHelpTooltip();

      drawInstaince.on("drawstart", (evt: DrawEvent) => {
        // set sketch
        sketch.current = evt.feature;

        let tooltipCoord: Coordinate | undefined = undefined;

        listener.current = sketch.current.getGeometry()?.on("change", (evt) => {
          const geom = evt.target;
          let output;
          if (geom instanceof Polygon) {
            output = formatArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            output = formatLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          }
          // console.log(output);
          if (measureTooltipElement.current) measureTooltipElement.current.innerHTML = output ?? "";
          if (measureTooltip.current) measureTooltip.current.setPosition(tooltipCoord);
        });
      });

      drawInstaince.on("drawend", function () {
        if (measureTooltipElement.current) measureTooltipElement.current.className = "ol-tooltip ol-tooltip-static";
        if (measureTooltip.current) measureTooltip.current.setOffset([0, -7]);
        // unset sketch
        sketch.current = undefined;
        // unset tooltip so that a new one can be created
        measureTooltipElement.current = undefined;
        createMeasureTooltip();
        if (listener.current) unByKey(listener.current);
      });

      // console.log("addInteraction", "add pointermove event");
      // map.on("pointermove", pointerMoveHandler);
    },
    [map, source /* , createHelpTooltip */]
  );

  const disableDraw = () => {
    if (map && draw) {
      map.removeInteraction(draw);
      setDraw(undefined);
      // console.log("disableDraw", "remove pointermove event");
      // map.un("pointermove", pointerMoveHandler);
      // removeHelpTooltip();
      map.getTargetElement().style.cursor = "";
    }
    setDrawType(undefined);
  };

  const clearDrawLayer = () => {
    if (source) {
      source.clear();
    }

    if (map) map.getOverlays().clear();
  };

  const onChangeDrawType = (ev: RadioChangeEvent) => {
    if (!ev.target.value === undefined) {
      disableDraw();
      return;
    }

    if (ev.target.value === "trash") {
      disableDraw();
      clearDrawLayer();
      return;
    }

    const drawShape = ev.target.value as "length" | "area";
    // console.log("onChangeDrawType", drawShape);
    if (map && draw) {
      map.removeInteraction(draw);
      disableDraw();
    }
    setDrawType(drawShape);
    addInteraction(drawShape);
  };

  useEffect(() => {
    if (map) {
      const isExist =
        (
          map
            .getLayers()
            .getArray()
            .find((layer) => layer instanceof VectorLayer) as any
        )?.id === "measure-layer";
      if (isExist) return;

      map.addLayer(vector);
    }
  }, [map, vector]);

  return (
    <div>
      <Radio.Group value={drawType} onChange={(ev) => onChangeDrawType(ev as any)} buttonStyle="solid" style={{ padding: "16px 0px 16px 64px" }}>
        <Radio.Button value="length">
          <FontAwesomeIcon icon="ruler" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />
        </Radio.Button>
        <Radio.Button value="area">
          <FontAwesomeIcon icon="triangle-circle-square" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />
        </Radio.Button>
        <Radio.Button>
          <FontAwesomeIcon icon="x" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />
        </Radio.Button>
        <Radio.Button value="trash">
          <FontAwesomeIcon icon="trash" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

const style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)",
  }),
  stroke: new Stroke({
    color: "rgba(0, 0, 0, 0.5)",
    lineDash: [10, 10],
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
  }),
});

const formatLength = function (line: LineString) {
  const length = getLength(line);
  let output;
  if (length > 100) {
    output = Math.round((length / 1000) * 100) / 100 + " km";
  } else {
    output = Math.round(length * 100) / 100 + " m";
  }
  return output;
};

const formatArea = function (polygon: Polygon) {
  const area = getArea(polygon);
  let output;
  if (area > 10000) {
    output = Math.round((area / 1000000) * 100) / 100 + " km<sup>2</sup>";
  } else {
    output = Math.round(area * 100) / 100 + " m<sup>2</sup>";
  }
  return output;
};
