import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMap } from "../app/MapContext";
import VectorSource from "ol/source/Vector";
import Draw, { DrawEvent } from "ol/interaction/Draw";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { Geometry } from "ol/geom";
import { unByKey } from "ol/Observable";
import { Vector as VectorLayer } from "ol/layer";
import { Feature } from "ol";
import { EventsKey } from "ol/events";
import { Radio, RadioChangeEvent } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DrawControl: React.FC = () => {
  const { map } = useMap();

  const source = useMemo(() => new VectorSource(), []);

  const vector = useMemo(() => {
    const instance = new VectorLayer({
      source,
      style: {
        "fill-color": "rgba(255, 255, 255, 0.2)",
        "stroke-color": "#ffcc33",
        "stroke-width": 2,
        "circle-radius": 7,
        "circle-fill-color": "#ffcc33",
      },
    });

    (instance as any).id = "draw-layer";
    return instance;
  }, [source]);

  const [draw, setDraw] = useState<Draw>();
  const [drawType, setDrawType] = useState<"point" | "line" | "polygon">();

  const sketch = useRef<Feature<Geometry>>();
  const listener = useRef<EventsKey>();

  const addInteraction = useCallback(
    (drawShape: "point" | "line" | "polygon") => {
      if (!map) return;

      const type = drawShape === "point" ? "Point" : drawShape === "line" ? "LineString" : "Polygon";
      const drawInstance = new Draw({
        source,
        type: type,
        style: (feature) => {
          const geometryType = feature.getGeometry()?.getType();
          if (geometryType === type || geometryType === "Point") {
            return style;
          }
        },
      });
      setDraw(drawInstance);
      map.addInteraction(drawInstance);
      map.getTargetElement().style.cursor = "crosshair";

      drawInstance.on("drawstart", (evt: DrawEvent) => {
        sketch.current = evt.feature;
      });

      drawInstance.on("drawend", function () {
        sketch.current = undefined;
        if (listener.current) unByKey(listener.current);
      });
    },
    [map, source]
  );

  const disableDraw = () => {
    if (map && draw) {
      map.removeInteraction(draw);
      setDraw(undefined);
      map.getTargetElement().style.cursor = "";
    }
    setDrawType(undefined);
  };

  const clearDrawLayer = () => {
    if (source) {
      source.clear();
    }

    if (map) map.getOverlays().clear();
  };

  const onChangeDrawType = (ev: RadioChangeEvent) => {
    if (!ev.target.value === undefined) {
      disableDraw();
      return;
    }

    if (ev.target.value === "trash") {
      disableDraw();
      clearDrawLayer();
      return;
    }

    const drawShape = ev.target.value as "point" | "line" | "polygon";
    if (map && draw) {
      map.removeInteraction(draw);
      disableDraw();
    }
    setDrawType(drawShape);
    addInteraction(drawShape);
  };

  useEffect(() => {
    if (map) {
      const isExist =
        (
          map
            .getLayers()
            .getArray()
            .find((layer) => layer instanceof VectorLayer) as any
        )?.id === "draw-layer";
      if (isExist) return;

      map.addLayer(vector);
    }
  }, [map, vector]);

  return (
    <div>
      <Radio.Group value={drawType} onChange={(ev) => onChangeDrawType(ev as any)} buttonStyle="solid" style={{ padding: "16px 0px 16px 64px" }}>
        <Radio.Button value="point">
          <FontAwesomeIcon icon="map-marker-alt" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />
        </Radio.Button>
        <Radio.Button value="line">
          <FontAwesomeIcon icon="pen" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />
        </Radio.Button>
        <Radio.Button value="polygon">
          <FontAwesomeIcon icon="triangle-circle-square" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />
        </Radio.Button>
        <Radio.Button value="trash">
          <FontAwesomeIcon icon="trash" size="lg" style={{ paddingLeft: 3, paddingRight: 3.5 }} />
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

const style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.2)",
  }),
  stroke: new Stroke({
    color: "rgba(0, 0, 0, 0.5)",
    lineDash: [10, 10],
    width: 2,
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 0.7)",
    }),
    fill: new Fill({
      color: "rgba(255, 255, 255, 0.2)",
    }),
  }),
});
