import BaseLayer from "ol/layer/Base";
import TileLayer from "ol/layer/Tile";
import { BingMaps, OSM } from "ol/source";

const BASE_LAYERS: BaseLayer[] = [new TileLayer({ visible: true, source: new OSM({}) })];

if (process.env.REACT_APP_BING_KEY) {
  const styles = ["RoadOnDemand", "Aerial", "AerialWithLabelsOnDemand", "CanvasDark", "OrdnanceSurvey"];
  for (let i = 0, ii = styles.length; i < ii; ++i) {
    BASE_LAYERS.push(
      new TileLayer({
        visible: false,
        preload: Infinity,
        source: new BingMaps({
          key: process.env.REACT_APP_BING_KEY, // "Your Bing Maps Key from https://www.bingmapsportal.com/ here",
          imagerySet: styles[i],
          // placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
        }),
      })
    );
  }
}

export default BASE_LAYERS;
