import { GeoserverTileLayerGroup } from "./type";

const geoserver_url = "https://geoserver.roite.dev/geoserver";
// const geoserver_url = "https://ahocevar.com/geoserver";

export const GEOSERVER_WMS_LAYERS_GROUPS: GeoserverTileLayerGroup[] = [
  /** Communication Network */
  {
    url: geoserver_url + "/roite/Communication_Network/wms",
    groupName: "roite",
  },

  /** District_Heating_Cooling_Network */
  {
    url: geoserver_url + "/roite/District_Heating_Cooling_Network/wms",
    groupName: "roite",
  },

  /** Electric_Traffic_Light_Network */
  {
    url: geoserver_url + "/roite/Electric_Traffic_Light_Network/wms",
    groupName: "roite",
  },

  /** Gas_Petroleum_Network */
  {
    url: geoserver_url + "/roite/Gas_Petroleum_Network/wms",
    groupName: "roite",
  },

  /** Hydro_Network */
  {
    url: geoserver_url + "/roite/Hydro_Network/wms",
    groupName: "roite",
  },

  /** Potable_Water_Network */
  {
    url: geoserver_url + "/roite/Potable_Water_Network/wms",
    groupName: "roite",
  },

  /** Waste_Storm_Water_Network */
  {
    url: geoserver_url + "/roite/Waste_Storm_Water_Network/wms",
    groupName: "roite",
  },

  /** Others */
  {
    url: "https://map.cadastru.md/geoserver/w_cbi/wms",
    groupName: "w_cbi",
    visibleLayerNames: [],
  },
];
