import React, { useState } from "react";
import { useMap } from "../app/MapContext";
import { Button, Flex, Tree } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { WmsLayerInfo } from "../config/type";

const { TreeNode } = Tree;

const ExtLayersControl: React.FC = () => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [wmsUrl, setWmsUrl] = useState<string>("https://geoserver.roite.dev/geoserver/roite/wms");
  const [layerName, setLayerName] = useState<string>("STRUCTURE AREA");
  const [extlayers, setExtlayers] = useState<WmsLayerInfo[]>([]);

  const { fetchAndAddLayerGroup, setLayerVisibility, getLayerVisibility } = useMap();

  const addExtlayer = (url: string, layerName: string) => {
    fetchAndAddLayerGroup(url, "EXT_LAYER_" + (extlayers.length + 1), [layerName], [layerName]).then((layer) => {
      setExtlayers((prev) => [...prev, ...layer]);
      setShowForm(false);
    });
  };

  const onCheck = (checkedKeys: any, nodeKey: string, checked: boolean) => {
    const [groupTitle, layerName] = nodeKey.split("#");

    if (nodeKey === groupTitle) {
      extlayers
        .filter((x) => x.groupTitle === groupTitle)
        .forEach((layer) => {
          setLayerVisibility(layer.layerName, checked);
        });
    } else {
      setLayerVisibility(layerName, checked);
    }
  };

  const renderTreeNodes = (layers: any[]) => {
    const groupedLayers = layers.reduce((acc, layer) => {
      if (!acc[layer.groupTitle]) {
        acc[layer.groupTitle] = [];
      }
      acc[layer.groupTitle].push(layer);
      return acc;
    }, {} as any);

    return Object.keys(groupedLayers).map((groupTitle) => (
      <TreeNode title={groupTitle} key={groupTitle} selectable={false}>
        {groupedLayers[groupTitle].map((layer: any) => (
          <TreeNode title={layer.layerTitle} key={`${layer.groupTitle}#${layer.layerName}`} />
        ))}
      </TreeNode>
    ));
  };

  const checkedKeys = extlayers.filter((layer) => getLayerVisibility(layer.layerName)).map((layer) => layer.groupTitle + "#" + layer.layerName);

  return (
    <div>
      <Flex align="center" justify="space-between" style={{ paddingLeft: 10, paddingRight: 12 }}>
        <h4 style={{ margin: "10px 0px" }}>External Layers</h4>
        <Button size="small" shape="circle" icon={<PlusOutlined />} onClick={() => setShowForm(!showForm)} />
      </Flex>
      <Tree
        checkable
        selectable={false}
        defaultExpandAll
        defaultCheckedKeys={checkedKeys}
        onCheck={(checkedKeys, info) => {
          onCheck(checkedKeys, info.node.key as string, info.checked);
        }}
      >
        {renderTreeNodes(extlayers)}
      </Tree>
      {showForm && (
        <Flex vertical align="center" justify="space-between" style={{ paddingLeft: 10, paddingRight: 12 }}>
          <input type="text" placeholder="WMS URL" value={wmsUrl} onChange={(e) => setWmsUrl(e.target.value)} style={{ width: "calc(100% - 40px)", padding: 4 }} />
          <input type="text" placeholder="Layer Name" value={layerName} onChange={(e) => setLayerName(e.target.value)} style={{ width: "calc(100% - 40px)", padding: 4 }} />
          <Button onClick={() => addExtlayer(wmsUrl, layerName)}>Add Layer</Button>
        </Flex>
      )}
      {/* <Button type="primary" style={{ margin: "12px 24px" }} onClick={() => addExtlayer("https://geoserver.roite.dev/geoserver/roite/wms", "STRUCTURE AREA")} /> */}
    </div>
  );
};

export default React.memo(ExtLayersControl);
