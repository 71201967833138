import React from "react";

type Props = {};

export const ZoomToRectangle: React.FC<Props> = () => {
  return (
    <div className="help">
      <div className="help-title">Help</div>
      <div className="help-body">Holding down the SHIFT key, draw a rectangle on the map and zoom the map to the size of the drawn rectangle.</div>
    </div>
  );
};
