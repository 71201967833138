import { Layout } from "antd";
import { ReactNode } from "react";
import SiderLayersControl from "./SiderLayersControl";
import { Header } from "./Header";
import { SiderToolbox } from "./SiderToolbox";
import logo from "../assets/img/logo.jpg";

const { /* Footer, */ Content: MapContainer } = Layout;

type Props = {
  children: ReactNode;
};

export const MapPageLayout: React.FC<Props> = ({ children }) => {
  return (
    <Layout style={{ width: "100%", height: "100vh" }}>
      <Header logoUrl={logo} />
      {/* logoUrl="https://geoportalinds.gov.md/viewer/src/app/assets/images/logo-english.svg"  />*/}
      <Layout>
        <SiderLayersControl />
        <MapContainer>{children}</MapContainer>
        <SiderToolbox />
      </Layout>
      {/* <Footer style={{ height: 24, padding: 0, textAlign: "center" }}>Footer</Footer> */}
    </Layout>
  );
};
