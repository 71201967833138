import { Control } from "ol/control";
import { useMap } from "../../app/MapContext";
import { FC, ReactNode, useEffect } from "react";
import { createRoot } from "react-dom/client";

type Props = {
  classname: string;
  children: ReactNode;
};
export const CustomControlButton: FC<Props> = ({ classname, children }) => {
  const { map } = useMap();

  useEffect(() => {
    if (!map) return;

    const element = document.createElement("div");
    element.className = `ol-unselectable ol-control custom-control ${classname}`;

    const customControl = new Control({
      element: element,
    });

    map.addControl(customControl);

    const root = createRoot(element);
    root.render(<>{children}</>);

    // Cleanup on unmount
    return () => {
      map.removeControl(customControl);
    };
  }, [children, classname, map]);

  return null;
};
