import { Coordinate } from "ol/coordinate";
import view from "../const/VIEW";
import { toCoordinate } from "./toCoordinate";

export const flyTo = (lon: number, lat: number, endZoom?: number, done?: (p?: any) => void) => {
  const coordinate: Coordinate = toCoordinate(lon, lat);

  const duration = 2000;
  const zoom = view.getZoom() ?? 2;
  let parts = 2;
  let called = false;
  function callback(complete?: any) {
    --parts;
    if (called) {
      return;
    }
    if (parts === 0 || !complete) {
      called = true;
      done && done(complete);
    }
  }
  view.animate(
    {
      center: coordinate,
      duration: duration,
    },
    callback
  );
  view.animate(
    {
      zoom: zoom - 1,
      duration: duration / 2,
    },
    {
      zoom: endZoom ? endZoom /* Math.max(endZoom, zoom) */ : zoom,
      duration: duration / 2,
    },
    callback
  );
};
