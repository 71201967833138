import React from "react";
import { Button, Flex, Tooltip } from "antd";

type Props = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  icon: React.ReactElement;
  title: string;
  contenRerender?: boolean;
  children?: React.ReactNode;
};

export const Toolbox: React.FC<Props> = ({ collapsed, setCollapsed, icon, title, children, contenRerender = false }) => {
  const [open, setOpen] = React.useState(false);

  const onOpen = () => {
    setCollapsed(false);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    // setCollapsed(true);
  };

  return (
    <>
      {collapsed ? (
        <Flex justify="center" align="center" vertical style={{ backgroundColor: "#fff", padding: "3px 0px" }}>
          <Tooltip title={title} placement="left">
            <Button icon={icon} onClick={onOpen} />
          </Tooltip>
        </Flex>
      ) : (
        <>
          <Flex
            justify="start"
            align="center"
            onClick={open ? onClose : onOpen}
            style={{
              cursor: "pointer",
              backgroundColor: open ? "#00aeef" : "#F2F2F2",
              color: open ? "white" : "black",
              borderTop: "1px solid #00aeef88",
              borderBottom: "1px solid #00aeef88",
              paddingLeft: 4,
            }}
          >
            <div style={{ display: "inline-block", padding: 6 /* background: "blue" */ }}>{icon}</div>
            <b>{title}</b>
            {/* {open ? (
              <Button icon={<ArrowUpOutlined style={{ height: 32, verticalAlign: "middle" }} />} onClick={onClose} />
            ) : (
              <Button icon={<ArrowDownOutlined style={{ height: 32, verticalAlign: "middle" }} />} onClick={onOpen} />
            )} */}
          </Flex>

          {contenRerender && open && <div>{children}</div>}
          {!contenRerender && <div style={{ display: open ? "block" : "none" }}>{children}</div>}
        </>
      )}
    </>
  );
};
