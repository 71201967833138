import React from "react";
import { useMap } from "../app/MapContext";
import { flyTo } from "../utils/flyTo";
import type { FormProps } from "antd";
import { Button, Form, Input } from "antd";

type FieldType = {
  lon?: string;
  lat?: string;
  zoom?: number;
};

type Props = {};

export const ZoomToCoordinate: React.FC<Props> = () => {
  const { map } = useMap();

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    // console.log("Success:", values);
    if (map) {
      const lon = parseFloat(values.lon || "0");
      const lat = parseFloat(values.lat || "0");
      const zoom = Number(values.zoom || "12");
      flyTo(lon, lat, zoom);
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      style={{ paddingTop: 24 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      initialValues={{ lon: "28.8322", lat: "47.0245", zoom: 17 }}
    >
      <Form.Item<FieldType> label="X" name="lon" rules={[{ required: true, message: "Longitude" }]}>
        <Input />
      </Form.Item>

      <Form.Item<FieldType> label="Y" name="lat" rules={[{ required: true, message: "Latitude" }]}>
        <Input />
      </Form.Item>

      <Form.Item<FieldType> label="Zoom" name="zoom" rules={[{ required: true, message: "Zoom" }]}>
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Go to Coordinate
        </Button>
      </Form.Item>
    </Form>
  );
};
